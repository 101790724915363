import styled from 'styled-components';

import Select from 'react-select';
import TextInput from 'common/components/TextInput/TextInput';
import Typography from 'common/components/Typography/Typography';
import Button from 'common/components/Button/Button';

export const ThreeDSDiv = styled.div`
  width: 100%;
  height: 400px;
  border: 0;
  background: transparent;
`;
export const ThreeDSMethodDiv = styled.div`
  width: 0;
  height: 0;
  border: 0;
  background: transparent;
`;

export const CustomComponentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const InputContainer = styled.div`
  width: 100%;
  position: relative;
  margin-top: 10px;

  @media (min-width: 576px) {
    margin-top: 0;
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  width: 98%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding-top: 20px;
`;

export const InputWithIcon = styled(TextInput)`
  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

export const BorderedInput = styled(TextInput)`
  border-radius: 20px;
  text-align: center;
  padding: none;

  & > input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button,
  input[type='number'] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
`;

export const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

export const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

export const TextContent = styled(Typography)`
  line-height: 24px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';

  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

export const FirstButton = styled(Button)`
  padding: 0 30px;
  margin: 0 auto;
  z-index: 0;

  @media (min-width: 768px) {
    margin: initial;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    margin-bottom: 80px;
  }

  @media only screen and (min-width: 1024px) and (max-width: 1150px) and (orientation: landscape) {
    margin-bottom: 120px;
  }
`;

export const ContentButton = styled(Typography)`
  line-height: 95%;
  font-family: 'HKGrotesk-Black';
`;

export const DropDown = styled(Select)`
  width: 100%;
`;

export const LightText = styled(Typography)`
  font-family: 'HKGrotesk-Light';
  line-height: 35px;
`;

export const ExpiryContainer = styled.div`
  display: flex;
`;

export const VisaContainer = styled.div`
  position: absolute;
  right: 30px;
  top: 32%;
`;

export const MastercardContainer = styled.div`
  position: absolute;
  right: 30px;
  top: 28%;
`;

export const AmexContainer = styled.div`
  position: absolute;
  right: 30px;
  top: 28%;
`;

export const WhiteButton = styled(Button)`
  padding: 0 30px;
  margin: 0 auto;
  font-size: 16px;
  width: 100%;
  border-width: 1px;
`;

export const ErrorContainer = styled.div`
  width: 100%;
  margin-top: 10px;
  padding-left: 70px;
`;

export const ErrorContainerInline = styled.div`
  width: 100%;
  margin-top: 10px;
`;

export const HostedFields = styled.div`
  padding: 10px 9px 23px 9px;
  margin-left: -3px;
  width: 100%;
`;

export const HostedFieldSmall = styled.div`
  width: 40%;
`;

export const HostedFieldLabel = styled.label`
  color: transparent;
`;

export const HostedFieldError = styled.p`
  color: #FFD600;
  font-size: 12px;
  margin-top: -19.5px;
  margin-left: 8.5px;
  padding-bottom: 7.5px;
`;

export const HostedForm = styled.form`
  width: 100%;
`;