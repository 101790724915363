import React, { useMemo, FC, useState, useEffect } from 'react';
import { useViewport } from 'use-viewport';
import { useFormik } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import * as R from 'ramda';

import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import Typography from 'common/components/Typography/Typography';
import TextInput from 'common/components/TextInput/TextInput';
import theme from 'theme/theme';

import { CountryCodes } from 'utils/country-codes';
import { USDistricts } from 'utils/us-districts';
import { useStoreState } from 'store/store';

import UserProfile from 'common/icons/UserProfile.icon';
import AddressIcon from 'common/icons/AddressIcon.icon';
import World from 'common/icons/World.icon';


import {
  BoldText,
  CloseButton,
  CustomComponentContainer,
  DropDown,
  InputContainer,
  LightText,
  WhiteButton,
} from '../Modals/styled/AddCardModal.styled';

import 'modules/account/pages/styles/AddressAutoComplete.css';
import { menuStyleFullWidth } from 'common/styles/DropdownStylingFullWidth';
import { CanadianDistricts } from 'utils/canadian-districts';
import restService from 'services/rest.service';

const VALIDATION_SCHEMA = Yup.object().shape({
  name: Yup.string().required('Your name is required.'),
  addressSearch: Yup.string().required('Your address is required.'),
  line1: Yup.string().required('Your address line 1 is required.'),
  line2: Yup.string(),
  city: Yup.string().required('Your address town/city is required.'),
  district: Yup.string(),
  zipCode: Yup.string().required('Your address postcode is required.'),
  country: Yup.string().required('Your address country is required.'),
});

interface ModalProps {
  onSubmit: () => void;
  onHide: () => void;
  address: any;
}

type OptionType = {
  value: string;
  label: string;
};

const AddressCaptureModal: FC<ModalProps> = ({ onSubmit, onHide, address }) => {
  const viewport = useViewport();

  const initialValues = useMemo(
    () => ({
      name: R.defaultTo('', address?.name as string),
      addressSearch: '',
      line1: R.defaultTo('', address?.line1 as string),
      line2: R.defaultTo('', address?.line2 as string),
      city: R.defaultTo('', address?.city as string),
      district: R.defaultTo('', address?.state as string),
      zipCode: R.defaultTo('', address?.postal_code as string),
      country: R.defaultTo('', address?.country as string),
    }),
    [address],
  );
  const {
    values,
    handleChange,
    setFieldValue,
    setFieldTouched,
    errors,
    touched,
  } = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: submitHandler,
  });

  const userLocation = useStoreState((state) => state.authentication.location);
  const [showDistrictField, setShowDistrictField] = useState<boolean>(false);
  const [showDistrictError, setDistrictError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);  

  const [addressSelected, setAddressSelected] = useState<boolean>(false);

  const [showValidationErrors, setShowValidationErrors] =
    useState<boolean>(false);

  useEffect(() => {

    if (address?.country) {
      setFieldValue('addressSearch', 'a');
      setAddressSelected(true)
    }

    if (userLocation) {
      setFieldValue('country', userLocation);

      if (userLocation === 'US' || userLocation === 'CA') {
        setShowDistrictField(true);
      } else {
        setShowDistrictField(false);
      }
    }

    const reactModal = Array.from(
      window.document.getElementsByClassName('ReactModal__Content '),
    )[0];
    reactModal.addEventListener('scroll', () => {
      const autocompleteHTMLElements = Array.from(
        window.document.getElementsByClassName('pac-container'),
      );
      autocompleteHTMLElements.forEach((element) => {
        element.setAttribute('style', 'display: none;');
      });
    });
  }, [address]);

  async function submitHandler() {
    setLoading(true)
    setShowValidationErrors(false);
    setDistrictError(false);

    if (errors) {
      const modal = document.getElementById('SBModal');
      if (modal) {
        modal.scrollTop = 0;
      }
    }

    if (values.country === 'US' || values.country === 'CA') {
      if (!values.district) {
        errors.district = 'District is required';
        setDistrictError(true);
      }
    }

    if (Object.keys(errors).length !== 0) {
      setShowValidationErrors(true);
      setLoading(false)
      return;
    }

    const postData = {
      name: values.name,
      line1: values.line1,
      line2: values.line2,
      city: values.city,
      state: values.district,
      postal_code: values.zipCode,
      country: values.country
    }

    await restService.address(postData).then((res) => {
      res
      onSubmit()
      setLoading(false)
    }).catch((error: any) => {
      error
      //console.log(error)
      setLoading(false)
    })

  }

  function handleClose() {
    onHide();
  }

  function getAddressComponentValue(
    place: google.maps.places.PlaceResult,
    types: string[],
  ) {
    const values: any = {};
    for (const type of types) {
      const value = place.address_components?.find((component) =>
        component.types.includes(type),
      )?.long_name;

      if (type) values[type] = value;
    }

    return values;
  }

  function handleAddressSearchSelected(place: google.maps.places.PlaceResult) {
    if (place.address_components) {
      //Set address
      const cityTypes = [
        'postal_town',
        'locality',
        'administrative_area_level_3',
      ];
      const types = ['street_number', 'route', 'postal_code', ...cityTypes];

      const addressComponents = getAddressComponentValue(place, types);

      let address = addressComponents['street_number'];
      address = address
        ? address + ' ' + addressComponents['route']
        : addressComponents['route'];

      setFieldValue('line1', address);
      setTimeout(() => {
        setFieldTouched('line1');
      }, 50);

      //Set city according to address
      let city = '';
      for (const cityType of cityTypes) {
        if (typeof addressComponents[cityType] !== 'undefined') {
          city = addressComponents[cityType];
          break;
        }
      }
      if (city) {
        setFieldValue('city', city);
        setTimeout(() => {
          setFieldTouched('city');
        }, 50);
      }

      //Set postal code accrding to address
      const postalCode = addressComponents['postal_code'];
      if (postalCode) {
        setFieldValue('zipCode', postalCode);
        setTimeout(() => {
          setFieldTouched('zipCode');
        }, 50);
      }

      setAddressSelected(true);
    }
  }

  return (
    <CustomComponentContainer id="addressCaptureModalContainer">
      <CloseButton onClick={handleClose} id="address-capture-close-button">
        X
      </CloseButton>
      <BoldText
        text={'enter your address'}
        fontWeight="bold"
        fontSize={viewport.width >= 576 ? 'fz48' : 'fz48'}
      />
      <Spacer height={20} />
      <>
        <div style={{ display: 'block', width: '100%' }}>
          <TextContent
            text="so we know where to send your perks"
            fontSize="fz18"
            fontWeight="bold"
            lineHeight="24px"
            fontColor={theme.colors.yellow}
            letterSpacing="-0.03em"
          />

          <InputContainer>
            <Icon>
              <UserProfile color={theme.colors.gray} />
            </Icon>
            <InputWithIcon
              className="name-field"
              inputName="name-input"
              height={70}
              type="text"
              placeholder="Full Name"
              value={values.name}
              tabIndex={0}
              withBottomLine
              autoComplete="cc-name"
              onChange={handleChange('name')}
              error={
                (showValidationErrors || touched.name) && errors.name
                  ? errors.name
                  : undefined
              }
            />
          </InputContainer>

          {!addressSelected && (
            <InputContainer>
              <Icon>
                <AddressIcon color={theme.colors.gray} />
              </Icon>
              <InputWithIcon
                height={70}
                type="search"
                placeholder="Start typing your address"
                value={values.addressSearch}
                tabIndex={0}
                inputName={'addressSearch'}
                withBottomLine
                autoComplete="off"
                onChange={handleChange('addressSearch')}
                error={
                  (showValidationErrors || touched.addressSearch) &&
                  errors.addressSearch &&
                  !addressSelected
                    ? errors.addressSearch
                    : undefined
                }
                googleAutoComplete={{
                  apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
                  onPlaceSelected: (place) =>
                    handleAddressSearchSelected(place),
                  options: {
                    componentRestrictions: {
                      country: values.country,
                    },
                    fields: ['address_components', 'geometry', 'name'],
                    types: ['address'],
                  },
                }}
              />
            </InputContainer>
          )}
          {addressSelected ? (
            <ColumnContainer>
              <InputContainer>
                <Icon>
                    <AddressIcon color={theme.colors.gray} />
                </Icon>
                <InputWithIcon
                  className="address-line-1"
                  inputName="address1-input"
                  height={70}
                  type="text"
                  placeholder="Address Line 1"
                  value={values.line1}
                  tabIndex={0}
                  withBottomLine
                  onChange={handleChange('line1')}
                  error={
                    (showValidationErrors || touched.line1) && errors.line1
                      ? errors.line1
                      : undefined
                  }
                />
              </InputContainer>
              <InputContainer>
                <AddressInput
                  className="address-line-2"
                  inputName="address2-input"
                  height={70}
                  type="text"
                  placeholder="Address Line 2"
                  value={values.line2}
                  tabIndex={0}
                  onChange={handleChange('line2')}
                  withBottomLine
                />
              </InputContainer>
              <InputContainer>
                <AddressInput
                  className="address-city"
                  inputName="address-city-input"
                  height={70}
                  type="text"
                  placeholder="Town/City"
                  value={values.city}
                  tabIndex={0}
                  withBottomLine
                  onChange={handleChange('city')}
                  error={
                    (showValidationErrors || touched.city) && errors.city
                      ? errors.city
                      : undefined
                  }
                />
              </InputContainer>
              {showDistrictField && (
                <div style={{ width: '90%' }}>
                  <DropdownContainer>
                    <DropDown
                      className="district-input"
                      classNamePrefix="district-input"
                      options={
                        values.country === 'US'
                          ? USDistricts
                          : CanadianDistricts
                      }
                      placeholder={
                        values.country === 'US'
                          ? 'Type State'
                          : 'Type District/State'
                      }
                      styles={menuStyleFullWidth}
                      onChange={(option) => {
                        setFieldValue('district', (option as OptionType).value);
                      }}
                    />
                  </DropdownContainer>

                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      marginTop: '10px',
                    }}>
                    <Typography
                      text={
                        (showValidationErrors || touched.district) &&
                        showDistrictError &&
                        !values.district
                          ? 'District is required'
                          : ''
                      }
                      fontSize="fz12"
                      fontColor={theme.colors.yellow}
                    />
                  </div>
                </div>
              )}
              <InputContainer>
                <AddressInput
                  className="address-zipcode"
                  inputName="address-zipcode-input"
                  height={70}
                  type="text"
                  placeholder={
                    values.country === 'UK' || values.country === 'GB'
                      ? 'Postcode'
                      : 'Zip Code'
                  }
                  value={values.zipCode}
                  tabIndex={0}
                  withBottomLine
                  onChange={handleChange('zipCode')}
                  error={
                    (showValidationErrors || touched.zipCode) && errors.zipCode
                      ? errors.zipCode
                      : undefined
                  }
                />
              </InputContainer>
            </ColumnContainer>
          ) : (
            <></>
          )}

          {values.country ? (
            <ColumnContainer>
              <Container>
                <Icon>
                  <World color={theme.colors.gray} />
                </Icon>
                <InnerContainer>
                  <LightText
                    fontColor="white"
                    text={'Country: ' + `${values.country}`}
                  />
                  <Button
                    label={
                      <Typography
                        text="Change"
                        fontColor={theme.colors.yellow}
                        fontWeight="regular"
                        letterSpacing="-0.03em"
                      />
                    }
                    onClick={() => {
                      setFieldValue('country', null);
                      setFieldValue('addressSearch', '');
                      //setAddressSelected(false);
                    }}
                  />
                </InnerContainer>
              </Container>
            </ColumnContainer>
          ) : (
            <ColumnContainer>
              <Container className='Container'>
                <Icon>
                  <World color={theme.colors.gray} />
                </Icon>
                <InnerContainer className='InnerContainer'>
                  <DropdownInnerContainer className='DropdownInnerContainer'>
                    <DropDown
                      id="address-search-country"
                      className="address-search-country"
                      name="address-search-country"
                      options={CountryCodes}
                      value={CountryCodes.find(
                        (data) => data.label === values.country,
                      )}
                      styles={menuStyleFullWidth}
                      placeholder="Search country"
                      onChange={(option) => {
                        const value = (option as OptionType).value;

                        setFieldValue('country', value);

                        if (value === 'US' || value === 'CA') {
                          setShowDistrictField(true);
                          if (!values.district) {
                            setDistrictError(true);
                          }
                        } else {
                          setShowDistrictField(false);
                        }
                      }}
                    />
                  </DropdownInnerContainer>
                </InnerContainer>
              </Container>
            </ColumnContainer>
          )}

          {!addressSelected ? (
            <>
              <Button
                className="manual-address-button"
                label={
                  <Typography
                    text="Add address manually"
                    fontColor={theme.colors.yellow}
                    fontWeight="regular"
                    letterSpacing="-0.03em"
                  />
                }
                onClick={() => {
                  setFieldValue('addressSearch', 'abc');
                  setAddressSelected(true);
                }}
              />
              <Spacer height={10} />
            </>
          ) : (
            <>
            <Button
                className="lookup-address-button"
                label={
                <Typography
                    text="Use address lookup"
                    fontColor={theme.colors.yellow}
                    fontWeight="regular"
                    letterSpacing="-0.03em"
                />
                }
                onClick={() => {
                setFieldValue('addressSearch', '');
                setAddressSelected(false);
                }}
            />
            <Spacer height={10} />
          </>
          )}

          <Spacer height={20} />

          <WhiteButton
            label="Submit"
            onClick={submitHandler}
            isLoading={loading}
            height={45}
            width={170}
            borderRadius={50}
            borderColor={theme.colors.yellow}
            bgColor={theme.colors.yellow}
            labelColor={theme.colors.black}
          />
        </div>
      </>
    </CustomComponentContainer>
  );
};

export const ColumnContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

`;

export const Icon = styled.div<{ bottom?: number; left?: number }>`
  position: absolute;
  bottom: 23px;
  left: 8px;
  bottom: ${(props) => (props.bottom ? props.bottom : 23)}px;
  left: ${(props) => (props.left ? props.left : 8)}px;
`;

export const InputWithIcon = styled(TextInput)`
  & > input {
    font-size: 18px;
    letter-spacing: -0.035em;

    padding-left: 37px;
    margin-top: 2px;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }

`;

export const AddressInput = styled(TextInput)`
  & > input {
      font-size: 18px;
      letter-spacing: -0.035em;
  }

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }

`;

export const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  width: auto;
  height: auto;
  border: none;
  border-color: ${theme.colors.yellow};
  border-radius: none;
  height: 70px;
  width: 100%; 
  display: flex
;
`;

export const InnerContainer = styled.div`
  padding-left: 37px;
  margin-top: 2px;
  display: flex;

  & > div:nth-child(3) {
    padding: 0 0 7px 8px;
  }
`;

export const TextContent = styled(Typography)`
  line-height: 24px;
  letter-spacing: -0.03em;
  font-family: 'HKGrotesk-Black';
`;


export const DropdownContainer = styled.div`
  display: flex;
`;

export const DropdownInnerContainer = styled.div`
  display: flex;
  width: 90%

`;


export default AddressCaptureModal;
