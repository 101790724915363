import React from 'react';
import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import { TypographyFontSizes, TypographyFontWeight } from 'common/common.types';
import Caption from 'common/components/Caption/Caption';
import Toggle from 'common/components/ToggleField/Toggle';

type ToggleFieldProps = {
  text: string | JSX.Element;
  fontSize?: keyof typeof TypographyFontSizes;
  fontWeight?: keyof typeof TypographyFontWeight;
  fontColor?: string;
  letterSpacing?: string;
  toggleField: string;
  toggle: boolean;
  setToggle: (value: boolean) => void;
  useTip: boolean;
  tip?: string;
  tipText: string;
};

const ToggleField: React.FC<ToggleFieldProps> = ({
  text,
  fontSize,
  fontWeight,
  fontColor,
  letterSpacing,
  toggleField,
  toggle,
  setToggle,
  useTip,
  tip,
  tipText,
}) => {
  return (
    <ToggleContainer>
      <Typography
        text={text}
        fontSize={fontSize ? fontSize : undefined}
        fontWeight={fontWeight ? fontWeight : undefined}
        fontColor={fontColor ? fontColor : undefined}
        letterSpacing={letterSpacing ? letterSpacing : undefined}
      />
      <ToggleBody>
        {useTip && <Caption text={tipText} tip={tip ? tip : undefined} />}
        <Toggle field={toggleField} toggle={toggle ? toggle : undefined} setToggle={setToggle} />
      </ToggleBody>
    </ToggleContainer>
  );
};

const ToggleContainer = styled.div`
  padding-left: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

const ToggleBody = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export default ToggleField;
