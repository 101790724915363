import * as React from 'react';

import { IconProps } from '../common.types';

const WorldIcon = ({ width = 16, height = 16, color = 'white' }: IconProps) => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.857 4.90323C10.3892 2.02258 9.28574 0 8.00161 0C6.71748 0 5.61404 2.02258 5.1462 4.90323H10.857ZM4.90421 8C4.90421 8.71613 4.94293 9.40323 5.01069 10.0645H10.9893C11.0571 9.40323 11.0958 8.71613 11.0958 8C11.0958 7.28387 11.0571 6.59677 10.9893 5.93548H5.01069C4.94293 6.59677 4.90421 7.28387 4.90421 8ZM15.3805 4.90323C14.4578 2.7129 12.5896 1.01935 10.2827 0.335484C11.07 1.42581 11.612 3.06774 11.8959 4.90323H15.3805ZM5.71728 0.335484C3.41359 1.01935 1.54225 2.7129 0.622706 4.90323H4.10728C4.38798 3.06774 4.93003 1.42581 5.71728 0.335484ZM15.7258 5.93548H12.025C12.0928 6.6129 12.1315 7.30645 12.1315 8C12.1315 8.69355 12.0928 9.3871 12.025 10.0645H15.7225C15.9 9.40323 16 8.71613 16 8C16 7.28387 15.9 6.59677 15.7258 5.93548ZM3.87175 8C3.87175 7.30645 3.91047 6.6129 3.97822 5.93548H0.277475C0.103247 6.59677 0 7.28387 0 8C0 8.71613 0.103247 9.40323 0.277475 10.0645H3.975C3.91047 9.3871 3.87175 8.69355 3.87175 8ZM5.1462 11.0968C5.61404 13.9774 6.71748 16 8.00161 16C9.28574 16 10.3892 13.9774 10.857 11.0968H5.1462ZM10.2859 15.6645C12.5896 14.9806 14.461 13.2871 15.3837 11.0968H11.8992C11.6152 12.9323 11.0732 14.5742 10.2859 15.6645ZM0.622706 11.0968C1.54547 13.2871 3.41359 14.9806 5.72051 15.6645C4.93325 14.5742 4.39121 12.9323 4.10728 11.0968H0.622706Z"
      fill={color}
    />
  </svg>
);

export default WorldIcon;
