import React, { FC, memo, useEffect } from 'react';
import SBModal from 'common/components/Modal/SBModal';
import styled from 'styled-components';
import Typography from 'common/components/Typography/Typography';
import theme from 'theme/theme';
import Button from 'common/components/Button/Button';
import Spacer from 'common/components/Spacer/Spacer';
import { useViewport } from 'use-viewport';
import { useNavigate } from 'react-router-dom';
import { SuccessModalType } from '../types';

import { useStoreActions } from 'store/store';
import { useAppDispatch } from 'hooks/reduxToolkit';
import { resetPersist } from 'store-persist/reducers/modalSlice';

import {
  setExpiryTime,
} from 'store-persist/reducers/modalSlice';

interface PaymentModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSuccess?: () => void;
  header: JSX.Element;
  type: SuccessModalType;
  charity: boolean;
}

const PurchaseSuccess: FC<PaymentModalProps> = ({
  isOpen,
  header,
  type,
  charity,
  onClose,
}) => {
  const viewport = useViewport();
  const navigate = useNavigate();

  const blockReload = useStoreActions(
    (actions) => actions.websocket.blockReload
  )

  const dispatch = useAppDispatch();
  const clearPurchaseStore = useStoreActions(
    (state) => state.purchase.resetPurchaseData,
  );

  useEffect(() => {
    if (isOpen) {
      dispatch(setExpiryTime(0));

      (window as any).gtag("event", "purchase_success", {});

    }
  }, [isOpen])

  return (
    <SBModal
      className={'purchase-success'}
      isOpen={isOpen}
      withProceedingText={true}
      withFeeText={true}
      feeIncludedInPrice={true}
      feeBottomPos="60px"
      height='662px'
      width="434px"
      mobileWidth='100%'
      top='10%'
      mobileTop='2%'
      content={
        <>
          <CloseButton
            onClick={() => {
              blockReload(false);
              onClose();
            }}>
            X
          </CloseButton>

          <BoldText
            text={
              type === SuccessModalType.Any
                ? 'payment'
                : type === SuccessModalType.Card
                ? 'card payment'
                : 'crypto payment'
            }
            fontWeight="bold"
            fontSize={viewport.width >= 576 ? 'fz48' : 'fz30'}
          />
          <Spacer height={20} />
          {header}
          <Spacer height={65} />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography text={'Payment Successful'} className="purchase-success-text" fontColor={theme.colors.white} fontWeight={'bold'} fontSize='fz24'></Typography>
          <Spacer height={25} />
          {charity && (
            <>
              <Typography text={'Thank you for your donation'} className="" fontColor={theme.colors.white} fontWeight={'light'} fontSize='fz18'></Typography>
              <Spacer height={25} />
            </>
          )}
          </div>
          
          {!charity && (
          <PaymentRow style={{ display: 'flex', flexDirection: 'column' }}>
            <FirstButton
              className='purchase-success-button'
              height={45}
              borderRadius={50}
              bgColor={theme.colors.yellow}
              labelColor={theme.colors.black}
              label={
                <TextContent
                  text={charity ? "View in my collection" : "View purchase in my collection"}
                  fontSize="fz16"
                  fontWeight="bold"
                  fontColor={theme.colors.black}
                />
              }
              onClick={() => {
                blockReload(false);
                clearPurchaseStore()
                dispatch(resetPersist());
                window.localStorage.removeItem('purchaseId');
                navigate('/library/my-collection');
              }}
            />
          </PaymentRow>
          )}
          
          <Spacer height={30} />
        </>
      }
    />
  );
};

const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
}>`
  letter-spacing: -0.03em;
  flex-shrink: 0;
  ${(props) => props.withUnderline && 'text-decoration: underline'};
  ${(props) => props.withCursorPointer && 'cursor: pointer;'};
`;

const FirstButton = styled(Button)`
  padding: 0 70px;
  width: 300px;

  @media (min-width: 576px) {
    width: 357px;
    margin-left: -5px;
  }
`;

const PaymentRow = styled.div`
  gap: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const CloseButton = styled.div`
  color: white;
  position: absolute;
  top: 28px;
  right: 25px;
  font-size: 20px;
  cursor: pointer;
`;

const BoldText = styled(Typography)`
  font-family: 'HKGrotesk-Black';
  letter-spacing: -0.03em;
`;

export default memo(PurchaseSuccess);
