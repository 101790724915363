import React from 'react';
import styled from 'styled-components';

import theme from 'theme/theme';

type ToggleProps = {
  field: string;
  toggle?: boolean;
  setToggle: (value: boolean) => void;
  onToggle?: () => void;
};

const Toggle: React.FC<ToggleProps> = ({
  toggle = false,
  field,
  setToggle,
  onToggle,
}) => {
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setToggle(e.target.checked);
    if (onToggle) onToggle();
  };

  return (
    <>
      <Input type="checkbox" id={field} className={field + '-' + Boolean(toggle).toString()} name={field} checked={toggle} onChange={onChange} />
      <Label htmlFor={field} />
    </>
  );
};

const Input = styled.input`
  visibility: hidden;

  &:checked + label {
    background: ${theme.colors.green};
  }

  &:checked + label:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }
`;

const Label = styled.label`
  cursor: pointer;
  text-indent: -9999px;
  min-width: 35px;
  height: 20px;
  background: ${theme.colors.gray};
  display: flex;
  align-items: center;
  border-radius: 27px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 2px;
    width: 16px;
    height: 16px;
    background: ${theme.colors.white};
    border-radius: 90px;
    transition: 0.3s;
  }

  &:active:after {
    width: 17px;
  }
`;

export default Toggle;
