import styled from 'styled-components';

import Typography from 'common/components/Typography/Typography';
import Button from 'common/components/Button/Button';
import theme from 'theme/theme';
import { sm } from 'utils/constants';

export const SongInfoContainer = styled.div<{ highlight?: boolean }>`
  z-index: ${(props) => (props.highlight ? 100 : 2)};
  width: 100%;
  display: flex;
  justify-content: stretch;
  padding: 20px 48px 10px 20px;
  gap: 10px;

  @media (max-height: 744px) {
    padding: 100px 30px 10px 20px;
  }

  @media (min-height: 845px) {
    padding: 100px 48px 10px 20px;
  }

  @media (max-height: 744px) and (max-width: 576px) {
    padding: 15px 30px 10px 20px;
  }

  @media (min-height: 845px) and (max-width: 576px) {
    padding: 15px 20px 10px 20px;
  }

  @media (min-width: 768px) {
    padding: 80px 48px 20px 48px;
  }
`;

export const SongDetailsContainer = styled.div`
  margin-top: -5px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media (min-width: 768px) {
    margin-left: 0;
  }
`;

export const BitPriceInfo = styled.div`
  display: flex;
  align-items: baseline;

  @media (min-width: 768px) {
    align-items: flex-end;
  }
`;

export const Trending = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;

  position: relative;
  bottom: 2px;

  margin-left: 10px;

  @media (min-width: ${sm}px) {
    position: absolute;
    right: 48px;
    bottom: initial;
    margin-left: 0;
    gap: 10px;
  }
`;

export const SongPoster = styled.img<{
  playing?: boolean;
}>`
  width: auto;
  object-fit: cover;
  height: 70px;
  float: left;

  ${(props) => props.playing && `filter: brightness(60%);`};

  @media (min-width: ${sm}px) {
    height: 150px;
    width: 150px;
  }
`;

export const BaseSongInfo = styled.div`
  display: block;
  margin-bottom: 15px;

  @media (min-width: ${sm}px) {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    align-items: flex-end;
    margin-bottom: 0;
  }
`;

export const ArtistProfileButton = styled(Button)`
  font-family: 'HKGrotesk-Black';
  padding: 0;
  margin-bottom: -5px;
  &:hover {
    background-color: transparent;
  }
`;

export const TextContent = styled(Typography)<{
  withUnderline?: boolean;
  withCursorPointer?: boolean;
  gap?: number;
  mobileHide?: boolean;
  marginBottom?: number;
}>`
  display: ${(props) => (props.mobileHide ? 'none' : 'inline-block')};
  font-size: ${(props) => (props.fontSize ? props.fontSize : 26)}px;
  letter-spacing: -0.03em;
  flex-shrink: 0;
  margin-bottom: ${(props) => (props.marginBottom ? props.marginBottom : 0)}px;
  ${(props) => props.withUnderline && 'text-decoration: underline;'}
  ${(props) => props.withCursorPointer && 'cursor: pointer;'}

  @media (min-width: 768px) {
    font-size: ${(props) => (props.fontSize ? props.fontSize : 32)}px;
    margin-bottom: 0;
  }

  @media (min-width: 992px) {
    font-size: ${(props) => (props.fontSize ? props.fontSize : 36)}px;
  }
`;

export const SongInfoText = styled(TextContent)<{
  small?: boolean;
  spaced?: boolean;
  size?: number;
  color?: string;
}>`
  color: ${(props) => (props.color ? props.color : theme.colors.yellow)};
  margin-right: ${(props) => (props.spaced ? 5 : 0)}px;
  vertical-align: middle;

  @media (max-width: 767.98px) {
    font-size: 16px;
    line-height: 20px;
  }
`;

export const SocialContainer = styled.div`
  display: inline-flex;
  gap: 10px;
  align-items: center;
  transform: translateY(3px);
  margin-bottom: 3px;
`;

export const InfoButton = styled(Button)`
  transform: scale(0.7);
  padding: 0;

  @media (min-width: 768px) {
    transform: none;
  }
`;

export const SocialLink = styled.a`
  display: flex;
  align-items: center;
`;

export const SongInformation = styled.div<{ wrap?: boolean; display?: string }>`
  max-width: 100vw;

  display: ${(props) =>
    props.display ? props.display : props.wrap ? 'block' : 'flex'};
  align-items: center;
  flex-wrap: wrap;

  margin-left: -80px;
  padding-bottom: 5px;
  gap: 2px 5px;

  @media (min-width: 768px) {
    margin-left: 0;
    gap: 10px;

    padding-bottom: 10px;
  }

  @media (min-width: 992px) {
    display: ${(props) =>
      props.display ? props.display : props.wrap ? 'block' : 'flex'};
    align-items: center;
    max-width: auto;
  }
`;

export const NoWrapContiner = styled.div`
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  column-gap: 10px;
`;

export const SocialShareContainer = styled.div`
  gap: 5px;
  align-items: center;
  transform: scale(0.8);

  display: flex;
  align-items: center;

  @media (min-width: 576px) {
    position: absolute;
    top: 12%;
    right: 20px;

    gap: 10px;
  }

  @media (min-width: 768px) {
    top: 13%;
    right: 25px;

    gap: 10px;
  }

  @media (min-width: 992px) {
    transform: none;
    right: 48px;

    gap: 10px;
  }
`;

export const SocialButton = styled(Button)`
  text-decoration: inherit;
  &:hover {
    background-color: rgba(0, 0, 0, 0);
  }
`;
