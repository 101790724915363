import React, { useState, FC } from 'react';

import styled from 'styled-components';

import theme from 'theme/theme';
import SBModal from 'common/components/Modal/SBModal';
import Button from 'common/components/Button/Button';
import Typography from 'common/components/Typography/Typography';
import Spacer from 'common/components/Spacer/Spacer';
import AddressCaptureModal from './AddressCaptureModal';


interface ComponentProps {
    deliveryInformationProvided: boolean;
    address: any;
    onSubmit: () => void;
    //refreshAccountSettings: () => void;
  }
  
const PerkDeliveryInformation: FC<ComponentProps> = ({
  address,
  deliveryInformationProvided,
    onSubmit,
    //refreshAccountSettings,
  }) => {

    const [showAddressCaptureModal, setShowAddressCaptureModal] = useState<boolean>(false);

  return (
    <Container>
      <Spacer height={20} />
      <EmailButtonContainer>
        {!deliveryInformationProvided ? (
          <>
            <Typography
              text={'Name & Address required'}
              fontSize="fz18"
                fontWeight='light'
              fontColor={theme.colors.white}
            />
            <EnableButton
              className={'enter-information-button'}
              label={'add'}
              onClick={() => setShowAddressCaptureModal(true)}
            />
          </>
        ) : (
          <FormColumn>
            <FormRow>
              <Typography
                text={address.name}
                fontSize="fz18"
                lineHeight='34px'
                fontWeight='light'
                fontColor={theme.colors.white}
              />
            </FormRow>
            <FormRow>
              <Typography
                text={address.line1}
                fontSize="fz18"
                lineHeight='24px'
                fontWeight='light'
                fontColor={theme.colors.white}
              />
            </FormRow>
            <FormRow>
              <Typography
                text={address.line2}
                fontSize="fz18"
                lineHeight='24px'
                fontWeight='light'
                fontColor={theme.colors.white}
              />
            </FormRow>
            <FormRow>
              <Typography
                text={address.city}
                fontSize="fz18"
                lineHeight='24px'
                fontWeight='light'
                fontColor={theme.colors.white}
              />
            </FormRow>
            <FormRow>
              <Typography
                text={address.postal_code}
                fontSize="fz18"
                lineHeight='24px'
                fontWeight='light'
                fontColor={theme.colors.white}
              />
              <EnableButton
                className={'edit-information-button'}
                disabled={!deliveryInformationProvided}
                label={'edit'}
                onClick={() => setShowAddressCaptureModal(true)}
              />
            </FormRow>
          </FormColumn>
        )}
      </EmailButtonContainer>

      <SBModal
        className={'address-capture'}
        isOpen={showAddressCaptureModal}
        width={'390px'}
        mobileHeight="90%"
        mobileTop="2%"
        mobileWidth={'100%'}
        overflow={'scroll'}
        content={
          <AddressCaptureModal
            address={address}
            onSubmit={() => {
              onSubmit()
              setShowAddressCaptureModal(false);
            }}
            onHide={() => {
                setShowAddressCaptureModal(false);
            }}
          />
        }
      />

    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  height: auto;
  position: relative;
`;

const EmailButtonContainer = styled.div`
  width: 100%;
  padding: 0 20px 0 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const EnableButton = styled(Button)`
  font-family: 'HKGrotesk-Regular';
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.03em;
  color: ${theme.colors.yellow};
`;

const FormColumn = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

const FormRow = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export default PerkDeliveryInformation;
