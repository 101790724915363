//import { string } from "yup";

import { StripeCVVResponse } from "utils/stripe";

export type AddCardSchema = {
  nickname: string;
  card: CardDetails;
  raw: {
    bin: string;
    last4: string;
    expMonth: string;
    expYear: string;
  }
  billingAddress: BillingDetails;
  browser: BrowserDetails;
  dateOfBirth: string;
}

export type CardSchema = {
  cardId: string;
  nickname: string;
  last4: string;
  active?: string;
  expMonth?: string;
  expYear?: string;
  verification_type?: string;
}

export type AddIncomingCryptoAddressSchema = {
  addressId: string;
  nickname: string;
  currency: string;
  chain: string;
}

export type AddOutgoingCryptoAddressSchema = {
  address: string;
  nickname: string;
  disconnected: boolean;
  chain: string;
}

export type CryptoAddressSchema = {
  address: string;
  nickname: string;
  currency: string;
  chain: string;
  status: string;
  addressTag?: string;
}

export type CardPaymentSchema = {
  metadata: PaymentMetadata;
  amount: AmountSchema;
  source: PaymentSource;
  autoCapture: boolean;
  verification: 'none' | 'cvv' | 'three_d_secure';
  verificationSuccessUrl: string;
  verificationFailureUrl: string;
}

export type BitPurchaseSchema = {
  // userWallet: string;
  bitUUID: string;
  numberOfBits: number;
  fakePurchase?: boolean;
  paymentAmount?: string;
  charityDonation?: boolean;
};

export type CancelPurchaseSchema = {
  // userWallet: string;
  purchaseId: string;
};

export type BitExportSchema = {
  id: string;
  code: string;
}

export type UpdateFakePurchaseSchema = {
  purchaseId: string;
  complete: boolean;
  sourceType: string;
  sourceId: string;
}

export type ConnectedWalletSchema = {
  nickname: string;
  address: string;
  chain: string;
}

export type BillingDetails = {
  line1: string;
  city: string;
  district?: string;
  postalCode: string;
  country: string;
}

export type CardDetails = {
  nameOnCard: string;
  cardNumber: string;
  expMonth: string;
  expYear: string;
  cvv: string;
}

export type PaymentDetails = {
  purchaseId: string;
  card: {
    cardId: string;
    cvv: StripeCVVResponse;
  }
};

export type BrowserDetails = {
  javaEnabled: boolean;
  language: string;
  colorDepth: number; // 1 | 4 | 8 | 15 | 16 | 24 | 32 | 48
  screenHeight: number;
  screenWidth: number;
  timeZoneOffset: string;
}

export type PaymentMetadata = {
  email: string;
  sessionId: string;
  ipAddress: string;
}

export type AmountSchema = {
  amount: string;
  currency: string;
}

export type PaymentSource = {
  type: 'card' | 'wire';
  id: string;
}

export interface OptionType {
  value: string;
  label: string;
}

export enum PaymentModalType {
  Normal,
  Quickflow,
  IFrame,
  AddFunds,
}

export enum SuccessModalType {
  Card,
  Crypto,
  Any,
}

export enum QuickFlowStates {
  Initial,
  AddFundsOptions,
  CardAuto,
  AddCard,
  CardPayment,
  CardFailed,
  CryptoAuto,
  AddCryptoAddress,
  CryptoPayment,
  PurchaseSuccess,
  ConnectWallet,
  CheckUSAState,
  USACryptoNotAvailable
}

export interface BalanceDetails {
  pendingBalance: string;
  availableBalance: string;
  settledBalance: string;
}
